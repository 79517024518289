import { combineEpics } from 'redux-observable'
import ClinicTableEpic from './ClinicTable/clinicTable.epic'
import loginEpic from './Login/login.epic'
/** RootEpic
 * @fileOverview Combine all the APIs calls w.r.t action in the redux
 * @author Athul George <athul@trillium-technologies.com>
 * @author  Akhil Francis <akhil@trillium-technologies.com>
 * @author Cinu James <cinu@trillium-technologies.com>
 */
const rootEpic = combineEpics(
  loginEpic.loginApi,
  loginEpic.logoutApi,
  // ClinicTableEpic.getAllClinics,
  ClinicTableEpic.getClinics,
  ClinicTableEpic.addClinic,
  ClinicTableEpic.fetchClinicById,
  ClinicTableEpic.getNotesById,
  ClinicTableEpic.addNote,
  ClinicTableEpic.getUsers,
  ClinicTableEpic.DeleteClinic,
  ClinicTableEpic.uploadFile,
  ClinicTableEpic.getSpeciality,
  ClinicTableEpic.getPrimCampaign,
  ClinicTableEpic.getTrilCampaign,
  ClinicTableEpic.getIrisCampaign,
  ClinicTableEpic.getCypCampaign,
  ClinicTableEpic.downloadFile,
  ClinicTableEpic.updateClinics,
  ClinicTableEpic.bulkDelete,
  ClinicTableEpic.sendEmail,
  ClinicTableEpic.checkEmailStatus,
)
export default rootEpic
