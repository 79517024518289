import React, { useContext, useEffect, useState } from 'react'
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Menu,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from '@material-ui/core'
import './style.css'
import clsx from 'clsx'
import FilterListIcon from '@material-ui/icons/FilterList'
import Nodata from '../../../assets/images/nodata-found.png'
import {
  fetchClinics,
  setLimit,
  setPage,
  setOrder,
  setSortField,
} from 'Redux/ClinicTable/clinicTable.actions'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { lighten, makeStyles } from '@material-ui/core/styles'
import { valueContext } from 'Context'
import toast from 'react-hot-toast'

const useHeadStyles = makeStyles((theme) => ({
  header: {
    backgroundColor: theme.palette.dull.main,
  },
  name: {
    fontWeight: '600',
  },
}))

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    cells,
  } = props
  const classes = useHeadStyles()

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            // indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            color="primary"
            // disabled
            inputProps={{
              'aria-label': 'Select all clinics',
            }}
          />
        </TableCell>

        {cells.map((headCell) => (
          <TableCell
            className={classes.header}
            key={headCell.id}
            align={'left'}
            padding="normal"
          >
            <TableSortLabel
              className={classes.name}
              direction={headCell.direction === true ? 'asc' : 'desc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  rowCount: PropTypes.number.isRequired,
}

const useStyles = makeStyles((theme) => ({
  main: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(5),
  },
  root: {
    width: '100%',
    height: '100%',
    position: 'relative',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
  },
  table: {
    minWidth: 750,
  },
  tableContainer: {
    height: '100vh',
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 100%',
  },
  filterIcon: {
    position: 'absolute',
    width: 'fit-content',
    zIndex: '5',
    bottom: '0',
  },
}))

const ClinicsTable = (props) => {
  const { setPage, setClinicIds } = useContext(valueContext)
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const [records, setRecords] = useState(0)
  const [selected, setSelected] = useState([])
  const {
    flag,
    setFlag,
    selectedItem,
    setselectedItem,
    trillCampaigns,
    primCampaigns,
    irisCampaigns,
    selectedClinics,
    setselectedClinics,
    rows,
    setRows,
    cypCampaigns,
  } = useContext(valueContext)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [mainCheck, SetmainCheck] = React.useState('null')
  const [cells, setCells] = useState([
    {
      id: 'clinicName',
      numeric: false,
      disablePadding: false,
      label: 'Clinic',
      direction: true,
      sorting: true,
    },
    {
      id: 'address',
      numeric: false,
      disablePadding: false,
      label: 'Address',
      direction: false,
      sorting: false,
    },
    {
      id: 'city',
      numeric: false,
      disablePadding: false,
      label: 'City',
      direction: true,
      sorting: true,
    },
    {
      id: 'state',
      numeric: false,
      disablePadding: false,
      label: 'St',
      direction: false,
      sorting: true,
    },
    // {
    //   id: 'zip',
    //   numeric: true,
    //   disablePadding: false,
    //   label: 'Zip',
    //   direction: false,
    //   sorting: false,
    // },
    {
      id: 'phone',
      numeric: true,
      disablePadding: false,
      label: 'Phone',
      direction: false,
      sorting: false,
    },
    {
      id: 'email',
      numeric: false,
      disablePadding: false,
      label: 'Email',
      direction: false,
      sorting: false,
    },
    // {
    //   id: 'frontdeskName',
    //   numeric: false,
    //   disablePadding: false,
    //   label: 'Frontdesk',
    //   direction: false,
    //   sorting: false,
    // },
    // {
    //   id: 'salesPerson',
    //   numeric: false,
    //   disablePadding: false,
    //   label: 'Sales Person',
    //   direction: false,
    //   sorting: false,
    // },
    // {
    //   id: 'officeManagerName',
    //   numeric: false,
    //   disablePadding: false,
    //   label: 'Office Manager',
    //   direction: false,
    //   sorting: false,
    // },
    // {
    //   id: 'physicianName',
    //   numeric: false,
    //   disablePadding: false,
    //   label: 'Physician',
    //   direction: false,
    //   sorting: false,
    // },
    // {
    //   id: 'emrName',
    //   numeric: false,
    //   disablePadding: false,
    //   label: 'EMR',
    //   direction: false,
    //   sorting: false,
    // },
    // {
    //   id: 'billing',
    //   numeric: false,
    //   disablePadding: false,
    //   label: 'Billing',
    //   direction: false,
    //   sorting: false,
    // },

    {
      id: 'speciality',
      numeric: false,
      disablePadding: false,
      label: 'Specialty',
      direction: false,
      sorting: false,
    },
    {
      id: 'trilliumMarketing',
      numeric: false,
      disablePadding: false,
      label: 'Trillium Marketing Campaign',
      direction: false,
      sorting: false,
    },
    {
      id: 'primroseMarketing',
      numeric: false,
      disablePadding: false,
      label: 'Primrose Marketing Campaign',
      direction: false,
      sorting: false,
    },
    {
      id: 'irisMarketing',
      numeric: false,
      disablePadding: false,
      label: 'Iris Marketing Campaign',
      direction: false,
      sorting: false,
    },
    {
      id: 'cypressMarketing',
      numeric: false,
      disablePadding: false,
      label: 'Cypress Marketing Campaign',
      direction: false,
      sorting: false,
    },
    // {
    //   id: 'estMonthlyRevenue',
    //   numeric: false,
    //   disablePadding: false,
    //   label: 'Expected Revenue',
    //   direction: false,
    //   sorting: false,
    // },
    // {
    //   id: 'opportunity',
    //   numeric: false,
    //   disablePadding: false,
    //   label: 'Opportunity',
    //   direction: false,
    //   sorting: false,
    // },
    // {
    //   id: 'priorAuth',
    //   numeric: false,
    //   disablePadding: false,
    //   label: 'Prior Auth',
    //   direction: false,
    //   sorting: false,
    // },
    // {
    //   id: 'coding',
    //   numeric: false,
    //   disablePadding: false,
    //   label: 'Coding',
    //   direction: false,
    //   sorting: false,
    // },

    // {
    //   id: 'marketingCampaign',
    //   numeric: false,
    //   disablePadding: false,
    //   label: 'Marketing Campaign',
    //   direction: false,
    //   sorting: false,
    // },
    {
      id: 'status',
      numeric: false,
      disablePadding: false,
      label: 'Status',
      direction: true,
      sorting: true,
    },
    // {
    //   id: 'createdDate',
    //   numeric: false,
    //   disablePadding: false,
    //   label: 'Created Date',
    //   direction: true,
    //   sorting: true,
    // },
    // {
    //   id: 'lastContact',
    //   numeric: false,
    //   disablePadding: false,
    //   label: 'Last Contact',
    //   direction: true,
    //   sorting: true,
    // },
  ])

  useEffect(() => {
    let newItem = []
    Object.keys(selectedItem).forEach((x) => {
      cells.forEach((j) => {
        if (x === j.id) {
          newItem.push({ ...j, status: selectedItem[x] })
        }
      })
    })
    setCells(newItem)
    newItem = []
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItem])

  const handleCheckbox = (e) => {
    setselectedItem({ ...selectedItem, [e.target.name]: e.target.checked })
  }

  const handleFilter = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleRequestSort = (event, property) => {
    cells.forEach((x) => {
      if (x.id === property && x.sorting === true) {
        x.active = true
        // console.log(x)
        x.direction = !x.direction
        props.setOrder(x.direction)
        props.setSortField(property)
      }
      if (x.id !== property) {
        x.active = false
      }
    })
  }

  const handleSelectAllClick = (event) => {
    if (event.target.checked === true) {
      SetmainCheck('checked')
      rows.forEach((x, i) => {
        // rows[i].status = 'checked'
      })
      setRows(rows)
      const newSelecteds = rows.map((n, i) => i)
      setSelected(newSelecteds)
      setselectedClinics(
        rows.map((clinic) => {
          return clinic.clinicId
        }),
      )
    } else {
      setselectedClinics([])
      SetmainCheck('')
      rows.forEach((x, i) => {
        // rows[i].status = ''
      })
      setRows(rows)
      setSelected([])
    }
  }

  const handleClick = (event, i, clinicId) => {
    const selectedIndex = selected.indexOf(i)
    const index = selectedClinics.indexOf(clinicId)
    let newSelected = []
    let newSelectedClinics = []
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, i)
      newSelectedClinics = newSelectedClinics.concat(selectedClinics, clinicId)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
      newSelectedClinics = newSelectedClinics.concat(selectedClinics.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
      newSelectedClinics = newSelectedClinics.concat(
        selectedClinics.slice(0, -1),
      )
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      )
      newSelectedClinics = newSelectedClinics.concat(
        selectedClinics.slice(0, index),
        selectedClinics.slice(index + 1),
      )
    }
    // console.log(newSelectedClinics)
    setSelected(newSelected)
    setselectedClinics(newSelectedClinics)
    // setIsItemSelected(true)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
    props.setPage(newPage + 1)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    props.setLimit(parseInt(event.target.value, 10))
  }

  const passId = (id) => {
    props.setStatus(1, id)
  }

  const isSelected = (id) => selected.indexOf(id) !== -1

  function findCampaign(array, value) {
    for (let i = 0; i < array.length + 1; i++) {
      if (value === '0') {
        return 'Completed'
      }
      if (array[i]?.value == value) {
        return array[i].name
      }
    }
  }

  useEffect(() => {
    if (
      props.ClinicsInfo &&
      props.ClinicsInfo.Updated &&
      props.ClinicsInfo.Updated.response
    ) {
      if (props.ClinicsInfo.Updated.response.responseCode === 0) {
        toast.success('Successfully Updated')
        props.backFunction()
        window.location.reload('false')
      }
      if (props.ClinicsInfo.Updated.response.responseCode === 131)
        toast.error('Error, Missing required details')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.ClinicsInfo.Updated.response])

  useEffect(() => {
    if (
      props.ClinicsInfo &&
      props.ClinicsInfo.ClinicsList &&
      props.ClinicsInfo.ClinicsList.response
    ) {
      // console.log('info:', props.ClinicsInfo.BulkDeleted)
      let data = props.ClinicsInfo.ClinicsList.response.data.results
      data.map((data) => {
        if (data.trilliumMarketing >= 0) {
          let id = data.trilliumMarketing
          const name = findCampaign(trillCampaigns, id)
          data.trilliumMarketingName = name
        }

        if (data.primroseMarketing) {
          let id = data.primroseMarketing
          const name = findCampaign(primCampaigns, id)
          data.primroseMarketingName = name
        }

        if (data.irisMarketing) {
          let id = data.irisMarketing
          const name = findCampaign(irisCampaigns, id)
          data.irisMarketingName = name
        }

        if (data.cypressMarketing) {
          let id = data.cypressMarketing
          const name = findCampaign(cypCampaigns, id)
          data.cypressMarketingName = name
        }
      })
      setRows(data)
      setClinicIds(
        data.map((row) => {
          return row.clinicId
        }),
      )
      setRecords(props.ClinicsInfo.ClinicsList.response.data.totalRecords)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.ClinicsInfo.ClinicsList.response])

  useEffect(() => {
    if (flag === true) {
      props.fetchClinics(props.values)
      setFlag(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (props.clearSelection === true) {
      // isSelected = false
      props.setClearSelection(false)
      // setFlag(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function formatPhoneNumber(phoneNumberString) {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3]
    }
    return null
  }

  // const formatDate = (dateMs) => {
  //   console.log('helo', dateMs)
  //   if (dateMs === null) {
  //     return null
  //   } else {
  //     let dateObject = new Date(dateMs)
  //     const d = [
  //       dateObject.getMonth() + 1,
  //       '/',
  //       dateObject.getDate(),
  //       '/',
  //       dateObject.getFullYear(),
  //     ]
  //     return d
  //   }
  // }

  return (
    <div className={clsx(classes.root, 'dataTable')}>
      <Paper elevation={0} className={classes.paper}>
        <TableContainer className={classes.tableContainer}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            stickyHeader
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              className={classes.header}
              selectedM={mainCheck}
              numSelected={selected.length}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              cells={cells.filter((x) => x.status === true)}
            />
            <TableBody>
              {rows.map((row, i) => {
                const isItemSelected = isSelected(i)
                return (
                  <TableRow
                    hover
                    className="pointer"
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.clinicId}
                    selected={isItemSelected}
                  >
                    <TableCell
                      onClick={(event) => handleClick(event, i, row.clinicId)}
                      class={
                        row.notes
                          ? 'notes-padding-notes checkbox-padding'
                          : 'notes-padding checkbox-padding'
                      }
                      padding="checkbox"
                    >
                      <Checkbox
                        color={'primary'}
                        checked={isItemSelected}
                        inputProps={{ 'aria-labelledby': props.clinicId }}
                        // checked={row.status === 'checked' ? true : false}
                      />
                    </TableCell>
                    {selectedItem.clinicName && (
                      <TableCell
                        title={'Click here to see the details'}
                        component="th"
                        scope="row"
                        onClick={() => passId(row.clinicId)}
                        align="left"
                      >
                        {row.clinicName}
                      </TableCell>
                    )}
                    {selectedItem.address && (
                      <TableCell align="left">{row.address}</TableCell>
                    )}
                    {selectedItem.city && (
                      <TableCell align="left">{row.city}</TableCell>
                    )}
                    {selectedItem.state && (
                      <TableCell align="left">{row.state}</TableCell>
                    )}
                    {/* {selectedItem.zip && (
                      <TableCell align="left">{row.zip}</TableCell>
                    )} */}
                    {selectedItem.phone && (
                      <TableCell align="left">
                        {formatPhoneNumber(row.phone)}
                      </TableCell>
                    )}
                    {selectedItem.email && (
                      <TableCell align="left">{row.email}</TableCell>
                    )}
                    {/* {selectedItem.frontdeskName && (
                      <TableCell align="left">{row.frontdeskName}</TableCell>
                    )} */}
                    {/* {selectedItem.salesPerson && (
                      <TableCell align="left">{row.salesPerson}</TableCell>
                    )} */}
                    {/* {selectedItem.officeManagerName && (
                      <TableCell align="left">
                        {row.officeManagerName}
                      </TableCell>
                    )} */}
                    {/* {selectedItem.physicianName && (
                      <TableCell align="left">{row.physicianName}</TableCell>
                    )} */}
                    {/* {selectedItem.emrName && (
                      <TableCell align="left">{row.emrName}</TableCell>
                    )} */}
                    {/* {selectedItem.billing && (
                      <TableCell align="left">{row.billing}</TableCell>
                    )} */}
                    {selectedItem.status && (
                      <TableCell align="left">{row.status}</TableCell>
                    )}
                    {selectedItem.speciality && (
                      <TableCell align="left">{row.speciality}</TableCell>
                    )}
                    {selectedItem.trilliumMarketing && (
                      <TableCell align="left">
                        {row.trilliumMarketingName}
                      </TableCell>
                    )}

                    {selectedItem.primroseMarketing && (
                      <TableCell align="left">
                        {row.primroseMarketing}
                      </TableCell>
                    )}

                    {selectedItem.irisMarketing && (
                      <TableCell align="left">{row.irisMarketing}</TableCell>
                    )}

                    {selectedItem.cypressMarketing && (
                      <TableCell align="left">{row.cypressMarketing}</TableCell>
                    )}

                    {/* {selectedItem.estMonthlyRevenue && (
                      <TableCell align="left">
                        {row.estMonthlyRevenue}
                      </TableCell>
                    )} */}
                    {/* {selectedItem.opportunity && (
                      <TableCell align="left">{row.opportunity}</TableCell>
                    )} */}
                    {/* {selectedItem.priorAuth && (
                      <TableCell align="left">{row.priorAuth}</TableCell>
                    )} */}
                    {/* {selectedItem.coding && (
                      <TableCell align="left">{row.coding}</TableCell>
                    )} */}
                    {/* {selectedItem.marketingCampaign && (
                      <TableCell align="left">
                        {row.marketingCampaign}
                      </TableCell>
                    )} */}

                    {/* {selectedItem.createdDate && (
                      <TableCell align="left">
                        {formatDate(row.createdDate)}
                      </TableCell>
                    )} */}
                    {/* {selectedItem.lastContact && (
                      <TableCell align="left">
                        {formatDate(row.lastContact)}
                      </TableCell>
                    )} */}
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
          {rows.length === 0 || rows === null ? (
            <div className="image-container">
              <div className="image-section">
                <img alt="no data found" className="img-nodata" src={Nodata} />
              </div>
            </div>
          ) : null}
        </TableContainer>
        <div className={classes.filterIcon}>
          <IconButton
            aria-label="more"
            id="long-button"
            aria-controls={open ? 'long-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleFilter}
          >
            <FilterListIcon />
          </IconButton>
        </div>

        <Menu
          className="columnMenu"
          id="long-menu"
          MenuListProps={{
            'aria-labelledby': 'long-button',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: {
              width: '60ch',
            },
          }}
        >
          <FormGroup>
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={0}>
                {cells.map((x, i) => (
                  <Grid item xs={3} key={i}>
                    <FormControlLabel
                      control={<Checkbox />}
                      onChange={(e) => handleCheckbox(e)}
                      name={x.id}
                      label={x.label}
                      checked={x.status}
                      labelPlacement="end"
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
          </FormGroup>
        </Menu>
        <TablePagination
          rowsPerPageOptions={[10, 20, 30, 40, 50, 60]}
          component="div"
          className="pagination"
          count={records}
          rowsPerPage={rowsPerPage}
          page={props.ClinicsInfo.PAGE - 1}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          style={{ overflow: 'hidden', borderTop: '1px solid #e0e0e0' }}
        />
      </Paper>
    </div>
  )
}

const mapStateToProps = (state) => ({
  ClinicsInfo: state.clinicTable,
})

const mapDispatchToProps = (dispatch) => ({
  fetchClinics: (values) => dispatch(fetchClinics(values)),
  setPage: (values) => dispatch(setPage(values)),
  setLimit: (values) => dispatch(setLimit(values)),
  setSortField: (values) => dispatch(setSortField(values)),
  setOrder: (values) => dispatch(setOrder(values)),
})

ClinicsTable.propTypes = {
  ClinicsInfo: PropTypes.object,
  fetchClinics: PropTypes.func,
  setPage: PropTypes.func,
  setLimit: PropTypes.func,
  setSortField: PropTypes.func,
  setOrder: PropTypes.func,
}

export default connect(mapStateToProps, mapDispatchToProps)(ClinicsTable)
