import {
  // GET_CLINICS,
  // GET_CLINICS_RESPONSE,
  CLINICS,
  CLINICS_RESPONSE,
  LIMIT,
  PAGE,
  SORT_FIELD,
  ORDER,
  CLINIC_BY_ID,
  CLINIC_BY_ID_RES,
  SAVE_CLINIC,
  SAVE_CLINIC_RES,
  GET_NOTES,
  GET_NOTES_RES,
  SAVE_NOTE,
  SAVE_NOTE_RES,
  GET_USERS,
  GET_USERS_RES,
  SEARCH_VALUES,
  DELETE_CLINIC,
  DELETE_CLINIC_RES,
  UPLOAD_FILE,
  FILE_UPLOAD_RES,
  GET_SPECIALITY,
  GET_SPECIALITY_RES,
  GET_TRIL_CAMPAIGN,
  GET_TRIL_CAMPAIGN_RES,
  GET_PRIM_CAMPAIGN,
  GET_PRIM_CAMPAIGN_RES,
  GET_IRIS_CAMPAIGN,
  GET_IRIS_CAMPAIGN_RES,
  GET_CYP_CAMPAIGN,
  GET_CYP_CAMPAIGN_RES,
  DOWNLOAD_FILE_RES,
  DOWNLOAD_FILE,
  UPDATE_CLINICS,
  UPDATE_CLINICS_RES,
  BULK_DELETE_CLINIC,
  BULK_DELETE_CLINIC_RES,
  SEND_EMAIL,
  GET_EMAIL_RES,
  CHECK_EMAIL_STATUS,
  GET_EMAIL_STATUS_RES,
} from './clinicTable.types'

// export const getClinics = (values) => ({
//   type: GET_CLINICS,
//   payload: values,
// })

// export const getClinicsResponse = (resp) => ({
//   type: GET_CLINICS_RESPONSE,
//   payload: resp,
// })

export const fetchClinics = (params) => ({
  type: CLINICS,
  payload: params,
})

export const fetchClinicsResponse = (resp) => ({
  type: CLINICS_RESPONSE,
  payload: resp,
})

export const setLimit = (number) => ({
  type: LIMIT,
  payload: number,
})
export const setPage = (number) => ({
  type: PAGE,
  payload: number,
})

export const setSortField = (field) => ({
  type: SORT_FIELD,
  payload: field,
})

export const setOrder = (order) => ({
  type: ORDER,
  payload: order,
})

export const setSearchValues = (values) => ({
  type: SEARCH_VALUES,
  payload: values,
})

export const saveClinic = (data) => ({
  type: SAVE_CLINIC,
  payload: data,
})

export const saveClinicResponse = (res) => ({
  type: SAVE_CLINIC_RES,
  payload: res,
})

export const deleteClinic = (data) => ({
  type: DELETE_CLINIC,
  payload: data,
})

export const saveDeletedResponse = (res) => ({
  type: DELETE_CLINIC_RES,
  payload: res,
})

export const bulkDelete = (data) => ({
  type: BULK_DELETE_CLINIC,
  payload: data,
})

export const saveBulkDeletedResponse = (res) => ({
  type: BULK_DELETE_CLINIC_RES,
  payload: res,
})

export const getClinicById = (id) => ({
  type: CLINIC_BY_ID,
  payload: id,
})

export const getClinicByIdResponse = (res) => ({
  type: CLINIC_BY_ID_RES,
  payload: res,
})

export const getNotes = (id) => ({
  type: GET_NOTES,
  payload: id,
})

export const getNotesRes = (res) => ({
  type: GET_NOTES_RES,
  payload: res,
})

export const saveNote = (id) => ({
  type: SAVE_NOTE,
  payload: id,
})

export const saveNoteRes = (res) => ({
  type: SAVE_NOTE_RES,
  payload: res,
})

export const getUsers = (data) => ({
  type: GET_USERS,
  payload: data,
})

export const getUsersResponse = (res) => ({
  type: GET_USERS_RES,
  payload: res,
})

export const uploadFile = (data) => ({
  type: UPLOAD_FILE,
  payload: data,
})

export const fileUploadResponse = (res) => ({
  type: FILE_UPLOAD_RES,
  payload: res,
})

export const getSpeciality = (data) => ({
  type: GET_SPECIALITY,
  payload: data,
})

export const getSpecialityResponse = (res) => ({
  type: GET_SPECIALITY_RES,
  payload: res,
})

export const getTrilCampaign = (data) => ({
  type: GET_TRIL_CAMPAIGN,
  payload: data,
})

export const getTrilCampaignResponse = (res) => ({
  type: GET_TRIL_CAMPAIGN_RES,
  payload: res,
})

export const getPrimCampaign = (data) => ({
  type: GET_PRIM_CAMPAIGN,
  payload: data,
})

export const getPrimCampaignResponse = (res) => ({
  type: GET_PRIM_CAMPAIGN_RES,
  payload: res,
})

export const getIrisCampaign = (data) => ({
  type: GET_IRIS_CAMPAIGN,
  payload: data,
})

export const getIrisCampaignResponse = (res) => ({
  type: GET_IRIS_CAMPAIGN_RES,
  payload: res,
})

export const getCypCampaign = (data) => ({
  type: GET_CYP_CAMPAIGN,
  payload: data,
})

export const getCypCampaignResponse = (res) => ({
  type: GET_CYP_CAMPAIGN_RES,
  payload: res,
})

export const downloadFile = (data) => ({
  type: DOWNLOAD_FILE,
  payload: data,
})

export const getDownloadFileResponse = (res) => ({
  type: DOWNLOAD_FILE_RES,
  payload: res,
})

export const updateClinics = (data) => ({
  type: UPDATE_CLINICS,
  payload: data,
})

export const getUpdatedClinicsResponse = (res) => ({
  type: UPDATE_CLINICS_RES,
  payload: res,
})

export const sendEmail = (data) => ({
  type: SEND_EMAIL,
  payload: data,
})

export const getEmailResponse = (res) => ({
  type: GET_EMAIL_RES,
  payload: res,
})

export const checkEmailStatus = (data) => ({
  type: CHECK_EMAIL_STATUS,
  payload: data,
})

export const getEmailStatusResponse = (res) => ({
  type: GET_EMAIL_STATUS_RES,
  payload: res,
})
