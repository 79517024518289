import {
  // GET_CLINICS_RESPONSE,
  CLINICS_RESPONSE,
  LIMIT,
  PAGE,
  ORDER,
  SORT_FIELD,
  SAVE_CLINIC_RES,
  CLINIC_BY_ID_RES,
  GET_NOTES_RES,
  SAVE_NOTE_RES,
  GET_USERS_RES,
  SEARCH_VALUES,
  DELETE_CLINIC_RES,
  FILE_UPLOAD_RES,
  GET_SPECIALITY_RES,
  GET_TRIL_CAMPAIGN_RES,
  GET_PRIM_CAMPAIGN_RES,
  GET_IRIS_CAMPAIGN_RES,
  GET_CYP_CAMPAIGN_RES,
  DOWNLOAD_FILE_RES,
  UPDATE_CLINICS_RES,
  BULK_DELETE_CLINIC_RES,
  GET_EMAIL_RES,
  GET_EMAIL_STATUS_RES,
} from './clinicTable.types'

const INITIAL_STATE = {
  // AllClinics: [],
  ClinicsList: [],
  PAGE: 1,
  LIMIT: 10,
  ORDER: true,
  SORTFIELD: '',
  ClinicDetails: {},
  ClinicById: {},
  Notes: null,
  NoteDetails: null,
  Users: [],
  searchValues: {},
  Deleted: {},
  BulkDeleted: [],
  Upload: [],
  SpecialityOptions: [],
  TrilMarketingCampaigns: {},
  PrimMarketingCampaigns: {},
  IrisMarketingCampaigns: {},
  CypMarketingCampaigns: {},
  Updated: [],
  Download: {},
  email: {},
  emailStatus: {},
}

const clinicTableReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // case GET_CLINICS_RESPONSE: {
    //   return { ...state, ...{ AllClinics: action.payload } }
    // }
    case CLINICS_RESPONSE: {
      return { ...state, ...{ ClinicsList: action.payload } }
    }
    case PAGE: {
      return { ...state, ...{ PAGE: action.payload } }
    }
    case LIMIT: {
      return { ...state, ...{ LIMIT: action.payload } }
    }
    case SORT_FIELD: {
      return { ...state, ...{ SORTFIELD: action.payload } }
    }
    case ORDER: {
      return { ...state, ...{ ORDER: action.payload } }
    }
    case SEARCH_VALUES: {
      return { ...state, ...{ searchValues: action.payload } }
    }
    case SAVE_CLINIC_RES: {
      return { ...state, ...{ ClinicDetails: action.payload } }
    }
    case DELETE_CLINIC_RES: {
      return { ...state, ...{ Deleted: action.payload } }
    }
    case BULK_DELETE_CLINIC_RES: {
      return { ...state, ...{ BulkDeleted: action.payload } }
    }
    case CLINIC_BY_ID_RES: {
      return { ...state, ...{ ClinicById: action.payload } }
    }
    case GET_NOTES_RES: {
      return { ...state, ...{ Notes: action.payload } }
    }
    case SAVE_NOTE_RES: {
      return { ...state, ...{ NoteDetails: action.payload } }
    }
    case GET_USERS_RES: {
      return { ...state, ...{ Users: action.payload } }
    }
    case FILE_UPLOAD_RES: {
      return { ...state, ...{ Upload: action.payload } }
    }
    case GET_SPECIALITY_RES: {
      return { ...state, ...{ SpecialityOptions: action.payload } }
    }
    case GET_TRIL_CAMPAIGN_RES: {
      return { ...state, ...{ TrilMarketingCampaigns: action.payload } }
    }
    case GET_PRIM_CAMPAIGN_RES: {
      return { ...state, ...{ PrimMarketingCampaigns: action.payload } }
    }
    case GET_IRIS_CAMPAIGN_RES: {
      return { ...state, ...{ IrisMarketingCampaigns: action.payload } }
    }
    case GET_CYP_CAMPAIGN_RES: {
      return { ...state, ...{ CypMarketingCampaigns: action.payload } }
    }
    case DOWNLOAD_FILE_RES: {
      return { ...state, ...{ Download: action.payload } }
    }
    case UPDATE_CLINICS_RES: {
      return { ...state, ...{ Updated: action.payload } }
    }
    case GET_EMAIL_RES: {
      return { ...state, ...{ email: action.payload } }
    }
    case GET_EMAIL_STATUS_RES: {
      return { ...state, ...{ emailStatus: action.payload } }
    }
    default:
      return state
  }
}

export default clinicTableReducer
