import React, { useState } from 'react'
import { createContext } from 'react'

export const valueContext = createContext()

const Context = ({ children }) => {
  const [page, setPage] = useState(0)
  const [flag, setFlag] = useState(false)
  const [condition, setCondition] = useState(2)
  const [isNewForm, setIsNewForm] = useState(false)
  const [userId, setUserId] = useState()
  const [selectedClinics, setselectedClinics] = useState([])
  const [speciality, setSpeciality] = useState([])
  const [clinicId, setClinicId] = useState(null)
  const [trillCampaigns, setTrillCampaigns] = useState([])
  const [primCampaigns, setPrimCampaigns] = useState([])
  const [irisCampaigns, setIrisCampaigns] = useState([])
  const [cypCampaigns, setCypCampaigns] = useState([])
  const [rows, setRows] = useState([])
  const [selectedItem, setselectedItem] = useState({
    clinicName: true,
    address: false,
    city: false,
    state: false,
    // zip: false,
    phone: false,
    email: true,
    // frontdeskName: false,
    // salesPerson: false,
    // officeManagerName: false,
    // pysicianName: false,
    // emrName: false,
    // billing: false,
    status: true,
    speciality: false,
    trilliumMarketing: true,
    primroseMarketing: false,
    irisMarketing: false,
    cypressMarketing: false,
    // estMonthlyRevenue: false,
    // opportunity: false,
    // priorAuth: false,
    // coding: false,
    // marketingCampaign: false,
    // createdDate: false,
    // lastContact: false,
  })

  const [clinicIds, setClinicIds] = useState([])
  return (
    <valueContext.Provider
      value={{
        page,
        setPage,
        flag,
        setFlag,
        selectedItem,
        setselectedItem,
        condition,
        setCondition,
        clinicIds,
        setClinicIds,
        isNewForm,
        setIsNewForm,
        clinicId,
        setClinicId,
        userId,
        setUserId,
        speciality,
        setSpeciality,
        trillCampaigns,
        setTrillCampaigns,
        primCampaigns,
        setPrimCampaigns,
        selectedClinics,
        setselectedClinics,
        irisCampaigns,
        setIrisCampaigns,
        cypCampaigns,
        setCypCampaigns,
        rows,
        setRows,
      }}
    >
      {children}
    </valueContext.Provider>
  )
}

export default Context
