import { useDropzone } from 'react-dropzone'
import React, { useState, useCallback, useMemo } from 'react'
// import XlsUploadIcon from '../../assets/icons/XlsUploadIcon.png'
import XlsxUploadIcon from '../../assets/icons/filetype-xlsx.svg'
import CloseIcon from '@material-ui/icons/Close'
import './style.css'

const baseStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: 'rgba(208, 239, 239, 0.27)',
  color: 'var(--color-grey-900)',
  fontWeight: '500',
  transition: 'border .3s ease-in-out',
  borderRadius: '6px',
}

const activeStyle = {
  borderColor: '#2196f3',
}

const acceptStyle = {
  borderColor: 'var(--Secondary-green)',
  backgroundColor: 'rgba(208, 239, 239, 0.8)',
}

const rejectStyle = {
  borderColor: 'rgba(255,85, 85, 0.7)',
  backgroundColor: 'rgba(255,0, 13, 0.03)',
}

function FileUploaderDropzone(props) {
  const [files, setFiles] = useState([])

  const onDrop = useCallback((acceptedFiles) => {
    setFiles(acceptedFiles)
    props.getFiles(acceptedFiles[0])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    accept: {
      // 'application/vnd.ms-excel': ['.xls'],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
        '.xlsx',
      ],
    },
    minSize: 0,
    multiple: false,
  })

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragAccept, isDragReject],
  )

  return (
    <div className="App">
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <img src={XlsxUploadIcon} alt="" className="icon" />
        {/* <p>Drag and drop files here or click to select files</p> */}
        {!isDragActive && 'Drag and drop files here or click to select files'}
        {isDragActive && !isDragReject && 'Drop the file here'}
        {isDragReject && 'File type not accepted! Please upload a .XLSX file.'}
      </div>
      {files.length ? (
        <div className="fileList">
          {files.length > 0 &&
            files.map((file) => (
              <div className="fileList__itemWrapper">
                <div className="fileList__item">
                  {file.name} <CloseIcon />
                </div>
              </div>
            ))}
        </div>
      ) : null}
    </div>
  )
}

export default FileUploaderDropzone
