import { ofType } from 'redux-observable'
import { switchMap, map, takeUntil } from 'rxjs/operators'
import { ajax as utlAjax } from 'Utils'
import {
  // GET_CLINICS,
  CLINICS,
  SAVE_CLINIC,
  REQUEST_CANCEL,
  CLINIC_BY_ID,
  GET_NOTES,
  SAVE_NOTE,
  GET_USERS,
  DELETE_CLINIC,
  UPLOAD_FILE,
  GET_SPECIALITY,
  GET_TRIL_CAMPAIGN,
  GET_PRIM_CAMPAIGN,
  GET_IRIS_CAMPAIGN,
  GET_CYP_CAMPAIGN,
  DOWNLOAD_FILE,
  UPDATE_CLINICS,
  BULK_DELETE_CLINIC,
  SEND_EMAIL,
  CHECK_EMAIL_STATUS,
} from './clinicTable.types'

import {
  // getClinicsResponse,
  fetchClinicsResponse,
  saveClinicResponse,
  getClinicByIdResponse,
  getNotesRes,
  saveNoteRes,
  getUsersResponse,
  saveDeletedResponse,
  fileUploadResponse,
  getSpecialityResponse,
  getPrimCampaignResponse,
  getTrilCampaignResponse,
  getIrisCampaignResponse,
  getCypCampaignResponse,
  getDownloadFileResponse,
  getUpdatedClinicsResponse,
  saveBulkDeletedResponse,
  getEmailResponse,
  getEmailStatusResponse,
} from './clinicTable.actions'
import environment from 'environments/environment'
const { baseUrl } = environment

const ClinicTableEpic = {}

// ClinicTableEpic.getAllClinics = (action$) =>
//   action$.pipe(
//     ofType(GET_CLINICS),
//     switchMap((action) =>
//       utlAjax({
//         url: `${baseUrl}/trillium-crm-api/v1/clinic/list/all`,
//         method: 'GET',
//       }).pipe(
//         map(
//           (response) => getClinicsResponse(response),
//           takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
//         ),
//       ),
//     ),
//   )

ClinicTableEpic.getClinics = (action$) =>
  action$.pipe(
    ofType(CLINICS),
    switchMap((action) =>
      utlAjax({
        url: `${baseUrl}/trillium-crm-api/v1/clinic/list/range?salesPerson=${
          action.payload.salesPerson
        }&status=${action.payload.status}&speciality=${
          action.payload.speciality
        }&email=${action.payload.email}&state=${action.payload.state}&city=${
          action.payload.city
        }&clinicName=${action.payload.clinic.replace(/\s+/g, ' ').trim()}&zip=${
          action.payload.zip
        }&emrName=${action.payload.emrName}&billing=${
          action.payload.billing
        }&phone=${action.payload.phone}&trilliumMarketing=${
          action.payload.trilliumMarketing
        }&primroseMarketing=${action.payload.primroseMarketing}&irisMarketing=${
          action.payload.irisMarketing
        }&cypressMarketing=${action.payload.cypressMarketing}&AddedDate=${
          action.payload.AddedDate
        }&NotAddedDate=${action.payload.NotAddedDate}&pagination=1&limit=${
          action.payload.limit
        }&isAscending=${action.payload.ascending}&page=${
          action.payload.page
        }&sortField=${action.payload.sortField}`,
        method: 'GET',
        body: action.payload,
      }).pipe(
        map(
          (response) => fetchClinicsResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )

ClinicTableEpic.addClinic = (action$) =>
  action$.pipe(
    ofType(SAVE_CLINIC),
    switchMap((action) =>
      utlAjax({
        headers: {
          'Content-Type': 'application/json',
        },
        url: `${baseUrl}/trillium-crm-api/v1/clinic/`,
        method: 'POST',
        body: action.payload,
      }).pipe(
        map(
          (response) => saveClinicResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )

ClinicTableEpic.updateClinics = (action$) =>
  action$.pipe(
    ofType(UPDATE_CLINICS),
    switchMap((action) =>
      utlAjax({
        headers: {
          'Content-Type': 'application/json',
        },
        url: `${baseUrl}/trillium-crm-api/v1/clinic/all`,
        method: 'PUT',
        body: action.payload,
      }).pipe(
        map(
          (response) => getUpdatedClinicsResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )

ClinicTableEpic.fetchClinicById = (action$) =>
  action$.pipe(
    ofType(CLINIC_BY_ID),
    switchMap((action) =>
      utlAjax({
        url: `${baseUrl}/trillium-crm-api/v1/clinic/${action.payload}`,
        method: 'GET',
      }).pipe(
        map(
          (response) => getClinicByIdResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )

ClinicTableEpic.DeleteClinic = (action$) =>
  action$.pipe(
    ofType(DELETE_CLINIC),
    switchMap((action) =>
      utlAjax({
        url: `${baseUrl}/trillium-crm-api/v1/clinic/${action.payload}`,
        method: 'DELETE',
      }).pipe(
        map(
          (response) => saveDeletedResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )

ClinicTableEpic.bulkDelete = (action$) =>
  action$.pipe(
    ofType(BULK_DELETE_CLINIC),
    switchMap((action) =>
      utlAjax({
        headers: {
          'Content-Type': 'application/json',
        },
        url: `${baseUrl}/trillium-crm-api/v1/clinic/all`,
        body: action.payload,
        method: 'DELETE',
      }).pipe(
        map(
          (response) => saveBulkDeletedResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )

ClinicTableEpic.getNotesById = (action$) =>
  action$.pipe(
    ofType(GET_NOTES),
    switchMap((action) =>
      utlAjax({
        url: `${baseUrl}/trillium-crm-api/v1/note/list/${action.payload}`,
        method: 'GET',
      }).pipe(
        map(
          (response) => getNotesRes(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )

ClinicTableEpic.addNote = (action$) =>
  action$.pipe(
    ofType(SAVE_NOTE),
    switchMap((action) =>
      utlAjax({
        url: `${baseUrl}/trillium-crm-api/v1/note/`,
        method: 'POST',
        body: action.payload,
      }).pipe(
        map(
          (response) => saveNoteRes(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )

ClinicTableEpic.getUsers = (action$) =>
  action$.pipe(
    ofType(GET_USERS),
    switchMap((action) =>
      utlAjax({
        url: `${baseUrl}/trillium-crm-api/v1/user/list/all`,
        method: 'GET',
      }).pipe(
        map(
          (response) => getUsersResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )

ClinicTableEpic.uploadFile = (action$) =>
  action$.pipe(
    ofType(UPLOAD_FILE),
    switchMap((action) =>
      utlAjax({
        url: `${baseUrl}/trillium-crm-api/v1/clinic/excel`,
        enctype: 'multipart/form-data',
        contentType: false,
        cache: false,
        processData: false,
        method: 'POST',
        body: action.payload,
      }).pipe(
        map(
          (response) => fileUploadResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )

ClinicTableEpic.getSpeciality = (action$) =>
  action$.pipe(
    ofType(GET_SPECIALITY),
    switchMap((action) =>
      utlAjax({
        url: `${baseUrl}/trillium-crm-api/v1/master/1`,
        method: 'GET',
      }).pipe(
        map(
          (response) => getSpecialityResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )

ClinicTableEpic.downloadFile = (action$) =>
  action$.pipe(
    ofType(DOWNLOAD_FILE),
    switchMap((action) =>
      utlAjax({
        url: `${baseUrl}/trillium-crm-api/v1/clinic/report/download?file=${action.payload}`,
        method: 'GET',
        responseType: 'blob',
      }).pipe(
        map(
          (response) => getDownloadFileResponse(response),
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )

ClinicTableEpic.getTrilCampaign = (action$) =>
  action$.pipe(
    ofType(GET_TRIL_CAMPAIGN),
    switchMap((action) =>
      utlAjax({
        url: `${baseUrl}/trillium-crm-api/v1/marketing/tril`,
        method: 'GET',
      }).pipe(
        map(
          (response) => getTrilCampaignResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )

ClinicTableEpic.getPrimCampaign = (action$) =>
  action$.pipe(
    ofType(GET_PRIM_CAMPAIGN),
    switchMap((action) =>
      utlAjax({
        url: `${baseUrl}/trillium-crm-api/v1/marketing/prim`,
        method: 'GET',
      }).pipe(
        map(
          (response) => getPrimCampaignResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )

ClinicTableEpic.getIrisCampaign = (action$) =>
  action$.pipe(
    ofType(GET_IRIS_CAMPAIGN),
    switchMap((action) =>
      utlAjax({
        url: `${baseUrl}/trillium-crm-api/v1/marketing/iris`,
        method: 'GET',
      }).pipe(
        map(
          (response) => getIrisCampaignResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )

ClinicTableEpic.getCypCampaign = (action$) =>
  action$.pipe(
    ofType(GET_CYP_CAMPAIGN),
    switchMap((action) =>
      utlAjax({
        url: `${baseUrl}/trillium-crm-api/v1/marketing/cyp`,
        method: 'GET',
      }).pipe(
        map(
          (response) => getCypCampaignResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )

ClinicTableEpic.sendEmail = (action$) =>
  action$.pipe(
    ofType(SEND_EMAIL),
    switchMap((action) =>
      utlAjax({
        url: `${baseUrl}/trillium-crm-api/v1/marketing/start/100`,
        method: 'GET',
      }).pipe(
        map(
          (response) => getEmailResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )

ClinicTableEpic.checkEmailStatus = (action$) =>
  action$.pipe(
    ofType(CHECK_EMAIL_STATUS),
    switchMap((action) =>
      utlAjax({
        url: `${baseUrl}/trillium-crm-api/v1/marketing/status`,
        method: 'GET',
      }).pipe(
        map(
          (response) => getEmailStatusResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )

export default ClinicTableEpic
