// export const GET_CLINICS = 'GET_CLINICS'
// export const GET_CLINICS_RESPONSE = 'GET_CLINICS_RESPONSE'
export const CLINICS = 'CLINICS'
export const CLINICS_RESPONSE = 'CLINICS_RESPONSE'
export const PAGE = 'PAGE'
export const LIMIT = 'LIMIT'
export const SORT_FIELD = 'SORT_FIELD'
export const ORDER = 'ORDER'
export const CLINIC_BY_ID = 'CLINIC_BY_ID'
export const CLINIC_BY_ID_RES = 'CLINIC_BY_ID_RES'
export const SAVE_CLINIC = 'SAVE_CLINIC'
export const SAVE_CLINIC_RES = 'SAVE_CLINIC_RES'
export const GET_NOTES = 'GET_NOTES'
export const GET_NOTES_RES = 'GET_NOTES_RES'
export const SAVE_NOTE = 'SAVE_NOTE'
export const SAVE_NOTE_RES = 'SAVE_NOTE_RES'
export const GET_USERS = 'GET_USERS'
export const GET_USERS_RES = 'GET_USERS_RES'
export const SEARCH_VALUES = 'SEARCH_VALUES'
export const REQUEST_CANCEL = 'REQUEST_CANCEL'
export const DELETE_CLINIC = 'DELETE_CLINIC'
export const DELETE_CLINIC_RES = 'DELETE_CLINIC_RES'
export const BULK_DELETE_CLINIC = 'BULK_DELETE_CLINIC'
export const BULK_DELETE_CLINIC_RES = 'BULK_DELETE_CLINIC_RES'
export const UPLOAD_FILE = 'UPLOAD_FILE'
export const FILE_UPLOAD_RES = 'FILE_UPLOAD_RES'
export const GET_SPECIALITY = 'GET_SPECIALITY'
export const GET_SPECIALITY_RES = 'GET_SPECIALITY_RES'
export const GET_TRIL_CAMPAIGN = 'GET_TRIL_CAMPAIGN'
export const GET_TRIL_CAMPAIGN_RES = 'GET_TRIL_CAMPAIGN_RES'
export const GET_PRIM_CAMPAIGN = 'GET_PRIM_CAMPAIGN'
export const GET_PRIM_CAMPAIGN_RES = 'GET_PRIM_CAMPAIGN_RES'
export const GET_IRIS_CAMPAIGN = 'GET_IRIS_CAMPAIGN'
export const GET_IRIS_CAMPAIGN_RES = 'GET_IRIS_CAMPAIGN_RES'
export const GET_CYP_CAMPAIGN = 'GET_CYP_CAMPAIGN'
export const GET_CYP_CAMPAIGN_RES = 'GET_CYP_CAMPAIGN_RES'
export const DOWNLOAD_FILE = 'DOWNLOAD_FILE'
export const DOWNLOAD_FILE_RES = 'DOWNLOAD_FILE_RES'
export const UPDATE_CLINICS = 'UPDATE_CLINICS'
export const UPDATE_CLINICS_RES = 'UPDATE_CLINICS_RES'
export const SEND_EMAIL = 'SEND_EMAIL'
export const GET_EMAIL_RES = 'GET_EMAIL_RES'
export const CHECK_EMAIL_STATUS = 'CHECK_EMAIL_STATUS'
export const GET_EMAIL_STATUS_RES = 'GET_EMAIL_STATUS_RES'
