import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Icon,
  IconButton,
} from '@material-ui/core'
import trilliumHealthLogo from '../../assets/images/trillium-health-logo.png'
import DeleteIcon from '@material-ui/icons/Delete'
import Searchbar from 'Components/Clinics/Searchbar'
import ClinicsTable from 'Components/Clinics/ClinicsTable'
import ClinicForm from 'Components/Clinics/ClinicForm'
import { makeStyles } from '@material-ui/core/styles'
import IdleTimerContainer from 'Utils/IdleTimerContainer'
import Notes from 'Components/Clinics/Notes'
import PublishIcon from '@material-ui/icons/Publish'
import './style.css'
import CloseIcon from '@material-ui/icons/Close'
import PropTypes from 'prop-types'
import {
  setSearchValues,
  getClinicById,
  fetchClinics,
  uploadFile,
  getSpeciality,
  getUsers,
  getTrilCampaign,
  getPrimCampaign,
  updateClinics,
  getIrisCampaign,
  bulkDelete,
  getCypCampaign,
  downloadFile,
  sendEmail,
  checkEmailStatus,
} from 'Redux/ClinicTable/clinicTable.actions'
import { logoutApi } from 'Redux/Login/login.actions'
import { useEffect } from 'react'
import { useContext } from 'react'
import { valueContext } from 'Context'
import FileUploaderDropzone from './FileUploaderDropzone'
import toast from 'react-hot-toast'
import Dropdown from 'BaseComponents/Dropdown'
import { useForm } from 'Hooks/validator'
import { ExitToApp } from '@material-ui/icons'
import { interval } from 'rxjs'

const useStyles = makeStyles({
  root: {
    padding: '0',
    height: 'calc(100vh - 71px)',
    backgroundColor: '#FFFFFF',
    overflowX: 'hidden',
  },
  container: {
    display: 'flex',
    justifyContent: 'start',
    gap: '0',
    flexWrap: 'nowrap ',
    height: '100%',
  },
  searchBarCard: {
    boxShadow: 'none !important',
    borderRadius: '15px',
  },
  grid1: {
    minWidth: '300px',
    // marginBottom: '1rem',
    '@media (max-width: 1440px)': {
      minWidth: '250px',
    },
  },
  Heading: {
    fontSize: '24px',
    fontWeight: '600',
    paddingLeft: '20px',
    color: '#3C4257',
  },
  table: {
    position: 'relative',
    width: 'calc(110vh -238px)',
  },
  plusButton: {
    position: 'absolute',
    bottom: '10%',
    right: '5%',
  },
  plusIcon: {
    display: 'flex',
    marginTop: '18px',
    fontSize: '3rem',
  },
})

const Clinics = (props) => {
  const history = useHistory()
  const classes = useStyles()
  const {
    setFlag,
    page,
    condition,
    setCondition,
    setIsNewForm,
    clinicId,
    setClinicId,
    setSpeciality,
    setTrillCampaigns,
    trillCampaigns,
    primCampaigns,
    irisCampaigns,
    cypCampaigns,
    selectedClinics,
    setselectedClinics,
    rows,
    setRows,
    setPrimCampaigns,
    setIrisCampaigns,
    setCypCampaigns,
  } = useContext(valueContext)
  const [open, setOpen] = React.useState(false)
  // const [clinics, setClinics] = useState([])
  const [isDelete, setIsDelete] = useState(false)
  const [isEmailSent, setIsEmailSent] = useState(false)
  const [openUploadDialog, setOpenUploadDialog] = useState(false)
  // const [isEmailSent, setIsEmailSent] = useState(false)
  const [responseCode, setResponseCode] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const [files, setFiles] = useState([])
  const [fileName, setFileName] = useState('')
  const [clearSelection, setClearSelection] = useState(false)
  const defaultValues = {
    salesPerson: '',
    status: '',
    clinic: '',
    city: '',
    speciality: '',
    state: '',
    zip: '',
    email: '',
    phone: '',
    AddedDate: '',
    trilliumMarketing: '',
    primroseMarketing: '',
    irisMarketing: '',
    cypressMarketing: '',
    NotAddedDate: '',
    emrName: '',
    billing: '',
    limit: props.clinicData.LIMIT,
    ascending: true,
    page: page + 1,
    sortField: '',
  }
  const lead = [
    { name: 'New clinic', value: 'New clinic' },
    { name: 'Validated', value: 'Validated' },
    { name: 'Disqualified', value: 'Disqualified' },
    { name: 'Converted', value: 'Converted' },
  ]

  const defaultUpdateValues = {
    trilliumMarketing: null,
    primroseMarketing: null,
    cypressMarketing: null,
    irisMarketing: null,
    status: 'New clinic',
  }

  const { useInput, isValid, values: data, setValues: setData } = useForm(
    defaultValues,
  )

  useEffect(() => {
    props.setSearchValues(defaultValues)
    // console.log('id', id)

    // setTimeout(() => {
    // setloading(true)
    // }, 2000)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [values, setValues] = useState(defaultValues)

  const handleClick = () => {
    setCondition(1)
    setClinicId(null)
    setIsNewForm(true)
    props.clinicData.ClinicById = {}
    props.clinicData.Notes = []
  }

  const backFunction = () => {
    history.push('/clinics')
    setClinicId(null)
    setIsNewForm(false)
    setCondition(2)
    setFlag(true)
    setValues({
      ...props.clinicData.searchValues,
      limit: props.clinicData.LIMIT,
      ascending: props.clinicData.ORDER,
      page: props.clinicData.PAGE,
      sortField: props.clinicData.SORTFIELD,
    })
    props.clinicData.Deleted.response = {}
    props.clinicData.ClinicDetails = {}
    props.clinicData.Upload.response = []
    props.clinicData.Updated.response = []
    // props.clinicData.ClinicById.response.data = {}
    // props.clinicData.Notes.response.data = []
  }

  const handleApiCall = (status, id) => {
    setCondition(status)
    props.getClinicById(id)
    setClinicId(id)
  }

  const handleOpenUploadDialog = () => {
    setOpenUploadDialog(true)
  }

  const handleCloseUploadDialog = () => {
    setOpenUploadDialog(false)
  }

  const getFiles = (files) => {
    setFiles(files)
  }

  useEffect(() => {
    if (
      props.clinicData &&
      props.clinicData.Upload &&
      props.clinicData.Upload.response
    ) {
      if (props.clinicData.Upload.response.responseCode === 0) {
        toast.success('Upload successfull')
        console.log(props.clinicData.Upload.response)
        setFileName(props.clinicData.Upload.response.data)
        props.downloadFile(props.clinicData.Upload.response.data)
        backFunction()
      } else if (props.clinicData.Upload.response.responseCode === 109) {
        toast.error('Upload error')
        backFunction()
      } else if (props.clinicData.Upload.response.responseCode === 117) {
        toast.error('Upload error')
        backFunction()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.clinicData.Upload.response])

  const download = (content, fileName, contentType) => {
    if (!contentType) contentType = 'application/octet-stream'
    var a = document.createElement('a')
    var blob = new Blob([content], { type: contentType })
    a.href = URL.createObjectURL(blob)
    a.download = fileName
    a.click()
    blob = null
  }

  useEffect(() => {
    if (
      props.clinicData &&
      props.clinicData.Download &&
      props.clinicData.Download.response
    ) {
      download(props.clinicData.Download.response, fileName)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.clinicData.Download.response])

  useEffect(() => {
    if (
      props.clinicData &&
      props.clinicData.SpecialityOptions &&
      props.clinicData.SpecialityOptions.response
    ) {
      let specialityArray = props.clinicData.SpecialityOptions.response.data
      setSpeciality(
        specialityArray.map((speciality) => {
          return { name: speciality.value, value: speciality.value }
        }),
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.clinicData.SpecialityOptions.response])

  useEffect(() => {
    if (
      props.clinicData &&
      props.clinicData.TrilMarketingCampaigns &&
      props.clinicData.TrilMarketingCampaigns.response
    ) {
      let trillArray = props.clinicData.TrilMarketingCampaigns.response.data
      // trillArray.push({ campaignName: 'hello', campaignId: 0 })
      setTrillCampaigns(
        trillArray.map((campaign) => {
          return {
            name: campaign.campaignName,
            value: campaign.campaignId.toString(),
          }
        }),
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.clinicData.TrilMarketingCampaigns.response])

  useEffect(() => {
    if (
      props.clinicData &&
      props.clinicData.PrimMarketingCampaigns &&
      props.clinicData.PrimMarketingCampaigns.response
    ) {
      let primArray = props.clinicData.PrimMarketingCampaigns.response.data
      setPrimCampaigns(
        primArray.map((campaign) => {
          return {
            name: campaign.campaignName,
            value: campaign.campaignId.toString(),
          }
        }),
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.clinicData.PrimMarketingCampaigns.response])

  useEffect(() => {
    if (
      props.clinicData &&
      props.clinicData.IrisMarketingCampaigns &&
      props.clinicData.IrisMarketingCampaigns.response
    ) {
      let IrisArray = props.clinicData.IrisMarketingCampaigns.response.data
      setIrisCampaigns(
        IrisArray.map((campaign) => {
          return {
            name: campaign.campaignName,
            value: campaign.campaignId.toString(),
          }
        }),
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.clinicData.IrisMarketingCampaigns.response])

  useEffect(() => {
    if (
      props.clinicData &&
      props.clinicData.CypMarketingCampaigns &&
      props.clinicData.CypMarketingCampaigns.response
    ) {
      let cypArray = props.clinicData.CypMarketingCampaigns.response.data
      setCypCampaigns(
        cypArray.map((campaign) => {
          return {
            name: campaign.campaignName,
            value: campaign.campaignId.toString(),
          }
        }),
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.clinicData.CypMarketingCampaigns.response])

  useEffect(() => {
    if (
      props.clinicData &&
      props.clinicData.BulkDeleted &&
      props.clinicData.BulkDeleted.response
    ) {
      if (props.clinicData?.BulkDeleted?.response?.responseCode === 0) {
        toast.success('Successfully Deleted')
        props.fetchClinics(values)
      }
    } else if (props.clinicData?.BulkDeleted?.response?.responseCode === 117)
      toast.error('could not delete')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.clinicData.BulkDeleted.response])

  const handleUploadSubmit = () => {
    let id = localStorage.getItem('userId')
    setOpenUploadDialog(false)
    // setOpenProgress(true)
    // const random = clinic_id + Math.random().toString(36).substring(2, 7)
    const data = new FormData()
    data.append('file', files)
    data.append('userId', id)
    // let url = `https://production.api.trillium.health/trillium-af-clinic-service/v1/reminder/progress/${random}`
    // const sse = new EventSource(url)
    props.uploadFile(data)
  }

  const handleLogout = () => {
    window.location.href = '/login'
    props.logoutApi()
    localStorage.clear()
  }

  const filterClinics = () => {
    let clinicArray = rows
    const filteredArray = clinicArray.filter((clinic) =>
      selectedClinics.includes(clinic.clinicId),
    )
    const finalArray = filteredArray.map((clinic) => {
      if (data.status) clinic = { ...clinic, status: data.status }
      if (data.trilliumMarketing)
        clinic = { ...clinic, trilliumMarketing: data.trilliumMarketing }
      if (data.primroseMarketing)
        clinic = { ...clinic, primroseMarketing: data.primroseMarketing }
      if (data.irisMarketing)
        clinic = { ...clinic, irisMarketing: data.irisMarketing }
      if (data.cypressMarketing)
        clinic = { ...clinic, cypressMarketing: data.cypressMarketing }
      return clinic
    })
    return finalArray
  }

  useEffect(() => {
    if (
      props.clinicData &&
      props.clinicData.email &&
      props.clinicData.email.response
    ) {
      if (props.clinicData?.email?.response?.responseCode === 0) {
        props.checkEmailStatus()
      } else if (props.clinicData?.email?.response?.responseCode === 117) {
        toast.error('Emails not sent')
        setIsEmailSent(false)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.clinicData.email.response])

  const handleEmail = (e) => {
    setIsEmailSent(true)
    e.preventDefault()
    props.sendEmail()
  }

  useEffect(() => {
    if (
      props.clinicData &&
      props.clinicData.emailStatus &&
      props.clinicData.emailStatus.response
    ) {
      if (props.clinicData.emailStatus.response.responseCode === 0) {
        setIsEmailSent(false)
        toast.success('Emails sent successfully')
      } else if (props.clinicData.emailStatus.response.responseCode === 503) {
        props.checkEmailStatus()
      } else setIsEmailSent(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.clinicData.emailStatus.response])

  const onUpdate = (event) => {
    const finalArray = filterClinics()
    props.updateClinics(finalArray)
  }

  const onBulkDelete = (event) => {
    const clinicIdArray = selectedClinics.map((clinic) => {
      return { clinicId: clinic }
    })
    props.bulkDelete(clinicIdArray)
    backFunction()
    setselectedClinics([])
  }

  const handleDelete = () => {
    setIsDelete(true)
  }

  const handleDeleteNo = () => {
    setIsDelete(false)
    // setIsSaveClicked(false)
  }

  const handleDeleteYes = () => {
    onBulkDelete()
    setselectedClinics([])
    setIsDelete(false)
  }

  useEffect(() => {
    props.getUsers()
    props.getSpeciality()
    props.getTrilCampaign()
    props.getPrimCampaign()
    props.getIrisCampaign()
    props.getCypCampaign()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="mainContainer">
      <IdleTimerContainer handleLogout={handleLogout} />
      <div className="clinic__header">
        <div className="clinic_header_logo">
          <img
            src={trilliumHealthLogo}
            alt="primrose-health-logo"
            className="clinicLogin__header__logo"
          />
        </div>
        <div className="updateBar">
          <div className="statusChange">
            <Dropdown
              label="Status"
              options={lead}
              {...useInput('status', {
                //   isRequired: {
                //     value: true,
                //     message: 'Select an option',
                //   },
              })}
            />
          </div>
          <div className="marketingCampaigns">
            <Dropdown
              label="Trillium"
              options={trillCampaigns}
              // disabled={
              //   values.email?.trim().length !== 0 && values.status === 'Validated'
              //     ? false
              //     : true
              // }
              {...useInput('trilliumMarketing', {
                //   isRequired: {
                //     value: true,
                //     message: 'Select an option',
                //   },
              })}
            />
          </div>
          <div className="marketingCampaigns">
            <Dropdown
              label="Primrose"
              options={primCampaigns}
              {...useInput('primroseMarketing', {
                //   isRequired: {
                //     value: true,
                //     message: 'Select an option',
                //   },
              })}
              // disabled={
              //   values.email?.trim().length !== 0 && values.status === 'Validated'
              //     ? false
              //     : true
              // }
            />
          </div>
          <div className="marketingCampaigns">
            <Dropdown
              label="Iris"
              options={irisCampaigns}
              {...useInput('irisMarketing', {
                //   isRequired: {
                //     value: true,
                //     message: 'Select an option',
                //   },
              })}
              // disabled={
              //   values.email?.trim().length !== 0 && values.status === 'Validated'
              //     ? false
              //     : true
              // }
            />
          </div>
          <div className="marketingCampaigns">
            <Dropdown
              label="Cypress"
              options={cypCampaigns}
              {...useInput('cypressMarketing', {
                //   isRequired: {
                //     value: true,
                //     message: 'Select an option',
                //   },
              })}
              // disabled={
              //   values.email?.trim().length !== 0 && values.status === 'Validated'
              //     ? false
              //     : true
              // }
            />
          </div>
          <div className="updateButton">
            <Button
              variant="outlined"
              color="secondary"
              onClick={onUpdate}
              disabled={selectedClinics.length === 0}
            >
              Update
            </Button>
          </div>
          <div className="updateButton">
            <div className="deleteDialogue">
              <Dialog
                open={isDelete}
                // onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  {'Are you sure you want to delete?'}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description"></DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    variant="outlined"
                    color="primary"
                    autofocus
                    onClick={handleDeleteNo}
                  >
                    No
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleDeleteYes}
                    style={{ margin: '6% 6px' }}
                    className="btn--white"
                  >
                    Yes
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
            <Button
              variant="outlined"
              color="primary"
              onClick={handleDelete}
              startIcon={<DeleteIcon />}
              disabled={selectedClinics.length === 0}
            >
              Delete
            </Button>
          </div>
          <div className="updateButton">
            <Button
              variant="outlined"
              color="primary"
              // style={{ marginLeft: '15px' }}
              onClick={(e) => handleEmail(e)}
              disabled={isEmailSent}
            >
              {isEmailSent ? (
                <span>Processing...</span>
              ) : (
                <span>Sent Email</span>
              )}
            </Button>
          </div>
        </div>
        {/* <div className="marketingCampaigns"> */}
        {/* <Dropdown
            label="Primrose Marketing Campaign"
            options={primCampaigns}
            // disabled={
            //   values.email?.trim().length !== 0 && values.status === 'Validated'
            //     ? false
            //     : true
            // }
          /> */}
        {/* </div> */}
        {/* <div className="marketingCampaigns"> */}
        {/* <Dropdown
            label="Iris Marketing Campaign"
            options={irisCampaigns}
            // disabled={
            //   values.email?.trim().length !== 0 && values.status === 'Validated'
            //     ? false
            //     : true
            // }
          /> */}
        {/* </div> */}
        {/* <div className="marketingCampaigns"> */}
        {/* <Dropdown
            label="Cypress Marketing Campaign"
            options={cypCampaigns}
            // disabled={
            //   values.email?.trim().length !== 0 && values.status === 'Validated'
            //     ? false
            //     : true
            // }
          /> */}
        {/* </div> */}
        <div className="menu_btn">
          <Button
            onClick={handleOpenUploadDialog}
            variant="outlined"
            color="primary"
            // disabled
            startIcon={<PublishIcon />}
            style={{
              margin: '6% 6px',
            }}
          >
            Upload
          </Button>
          <Dialog
            open={openUploadDialog}
            onClose={handleCloseUploadDialog}
            fullWidth={true}
            maxWidth={'sm'}
            className="uploadCsvDialog"
          >
            <div className="uploadCsvDialog__header">
              <h3>Upload</h3>
              <IconButton
                aria-label="close dialog"
                onClick={handleCloseUploadDialog}
              >
                <CloseIcon />
              </IconButton>
            </div>
            <div className="uploadCsvDialog__uploadArea">
              <FileUploaderDropzone getFiles={(e) => getFiles(e)} />
            </div>
            <div className="uploadCsvDialog__btnWrapper">
              <Button
                className={`${classes.filterButton} ${classes.browseFilesbtn}`}
                size="small"
                variant="outlined"
                color={'primary'}
                onClick={handleCloseUploadDialog}
              >
                Cancel
              </Button>
              <Button
                className="btn--white"
                variant="contained"
                color="primary"
                size="medium"
                onClick={() => handleUploadSubmit()}
              >
                Submit
              </Button>
            </div>
          </Dialog>
          <Button
            onClick={handleLogout}
            variant="contained"
            color="primary"
            startIcon={<ExitToApp />}
            className="btn--white"
            // startIcon={<PublishIcon />}
            style={{
              margin: '6% 6px',
            }}
          >
            Logout
          </Button>
        </div>
      </div>
      <div className={classes.root}>
        <Grid container className={classes.container}>
          <Grid item xs={2} sm={2} className={classes.grid1}>
            <Searchbar />
          </Grid>

          {condition === 1 ? (
            <Grid container spacing={1}>
              <Grid
                item
                xs={5}
                sm={7}
                style={{ height: '100%' }}
                // className={classes.grid1}
              >
                <ClinicForm
                  backFunction={backFunction}
                  getClinicId={(e) => setClinicId(e)}
                  clinicId={clinicId}
                />
                {/* <div className="navButtonGroup"> */}
                {/* <Button
                    variant="outlined"
                    color="primary"
                    className="navButton"
                    style={{ margin: '6% 6px' }}
                  >
                    Previous
                  </Button>
                  <Button
                    onClick={handleNext}
                    variant="outlined"
                    color="primary"
                    className="navButton"
                    style={{ margin: '6% 6px' }}
                  >
                    Next
                  </Button> */}
                {/* </div> */}
              </Grid>

              <Grid
                item
                xs={5}
                sm={5}
                // className={classes.grid1}
              >
                <Notes clinicId={clinicId} />
              </Grid>
            </Grid>
          ) : (
            <Grid
              item
              xs={10}
              sm={10}
              style={{ height: '100%' }}
              className={classes.table}
            >
              <ClinicsTable
                fetClinics={props.fetClinics}
                values={values}
                backFunction={backFunction}
                setStatus={(status, id) => handleApiCall(status, id)}
                clearSelection={clearSelection}
                setClearSelection={setClearSelection}
              />

              <Button className={classes.plusButton} onClick={handleClick}>
                <Icon color="primary" className={classes.plusIcon}>
                  add_circle
                </Icon>
              </Button>
            </Grid>
          )}
        </Grid>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  clinicData: state.clinicTable,
  logData: state.login,
})

const mapDispatchToProps = (dispatch) => ({
  getClinicById: (values) => dispatch(getClinicById(values)),
  logoutApi: (values) => dispatch(logoutApi(values)),
  fetchClinics: (values) => dispatch(fetchClinics(values)),
  getUsers: (values) => dispatch(getUsers(values)),
  setSearchValues: (values) => dispatch(setSearchValues(values)),
  uploadFile: (values) => dispatch(uploadFile(values)),
  getSpeciality: (values) => dispatch(getSpeciality(values)),
  getTrilCampaign: (values) => dispatch(getTrilCampaign(values)),
  getPrimCampaign: (values) => dispatch(getPrimCampaign(values)),
  getIrisCampaign: (values) => dispatch(getIrisCampaign(values)),
  getCypCampaign: (values) => dispatch(getCypCampaign(values)),
  downloadFile: (values) => dispatch(downloadFile(values)),
  updateClinics: (values) => dispatch(updateClinics(values)),
  bulkDelete: (values) => dispatch(bulkDelete(values)),
  sendEmail: (values) => dispatch(sendEmail(values)),
  checkEmailStatus: (values) => dispatch(checkEmailStatus(values)),
})

Clinics.propTypes = {
  clinicData: PropTypes.object,
  getClinicById: PropTypes.func,
  logData: PropTypes.object,
  logoutApi: PropTypes.func,
  fetchClinics: PropTypes.func,
  getUsers: PropTypes.func,
  setSearchValues: PropTypes.func,
  uploadFile: PropTypes.func,
  getSpeciality: PropTypes.func,
  getPrimCampaign: PropTypes.func,
  getTrilCampaign: PropTypes.func,
  getIrisCampaign: PropTypes.func,
  getCypCampaign: PropTypes.func,
  downloadFile: PropTypes.func,
  updateClinics: PropTypes.func,
  bulkDelete: PropTypes.func,
  sendEmail: PropTypes.func,
  checkEmailStatus: PropTypes.func,
}

export default connect(mapStateToProps, mapDispatchToProps)(Clinics)
