import React, { useContext, useEffect } from 'react'
import { useForm } from 'Hooks/validator'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import './style.css'
import { Button, Grid, Paper, Typography } from '@material-ui/core'
import {
  fetchClinics,
  setLimit,
  setPage,
  setSearchValues,
} from 'Redux/ClinicTable/clinicTable.actions'
import Dropdown from 'BaseComponents/Dropdown'
import Input from 'BaseComponents/Input'
import { useState } from 'react'
import { valueContext } from 'Context'
import { states } from 'Helpers/states'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  searchFormContainer: {
    height: '100%',
    minWidth: '30%',
    padding: '18px 20px 0 20px',
    borderRight: '2px solid #F2F5FA',
  },
  paperStyle: {
    padding: '0 10px',
    borderRadius: '6px',
    height: '100%',
  },
  btnAlign: {
    textAlign: 'center',
    paddingTop: '15px',
    paddingBottom: '15px',
  },
  heading: {
    fontSize: '1.5rem',
    fontWeight: 600,
    color: '#3C4257',
  },
}))

const days = [
  { name: '-- None selected --', value: '' },
  { name: '1 day', value: 1 },
  { name: '10 days', value: 10 },
  { name: '30 days', value: 30 },
  { name: '60 days', value: 60 },
  { name: '90 days', value: 90 },
]

const lead = [
  { name: 'New clinic', value: 'New clinic' },
  { name: 'Validated', value: 'Validated' },
  { name: 'Disqualified', value: 'Disqualified' },
  { name: 'Converted', value: 'Converted' },
]
// const emrName = [
//   { name: '-- ALL --', value: '' },
//   { name: 'eCw', value: 'eCw' },
//   { name: 'Allscripts', value: 'Allscripts' },
//   { name: 'Athena health', value: 'Athena health' },
//   { name: 'AdvancedMD', value: 'Advanced MD' },
//   { name: 'EPIC', value: 'EPIC' },
//   { name: 'PracticeFusion', value: 'PracticeFusion' },
//   { name: 'Kareo', value: 'Kareo' },
//   { name: 'Greenway', value: 'Greenway' },
//   { name: 'NextGen', value: 'NextGen' },
//   { name: 'Cerner', value: 'Cerner' },
//   { name: 'Other', value: 'Other' },
// ]

// const billing = [
//   { name: '-- ALL --', value: '' },
//   { name: 'Inside billing', value: 'Inside billing' },
//   { name: 'Outside management company', value: 'Outside management company' },
//   { name: 'EMR Vendor', value: 'EMR Vendor' },
//   { name: 'AthenaHealth', value: 'AthenaHealth' },
//   { name: 'eCw', value: 'eCw' },
//   { name: '99 Management', value: '99 Management' },
// ]

const Searchbar = (props) => {
  const classes = useStyles()
  const [users, setUsers] = useState([])

  const defaultValues = {
    salesPerson: '',
    speciality: '',
    status: '',
    clinic: '',
    email: '',
    city: '',
    trilliumMarketing: '',
    primroseMarketing: '',
    irisMarketing: '',
    cypressMarketing: '',
    state: '',
    phone: '',
    zip: '',
    emrName: '',
    billing: '',
    AddedDate: '',
    NotAddedDate: '',
  }

  const { useInput, isValid, values, setValues } = useForm(defaultValues)
  const {
    setCondition,
    speciality,
    trillCampaigns,
    primCampaigns,
    irisCampaigns,
    cypCampaigns,
  } = useContext(valueContext)

  // const checkEmpty = () => {
  //   if (
  //     values.salesPerson.length === 0 &&
  //     values.status.length === 0 &&
  //     values.clinic.length === 0 &&
  //     values.city.length === 0 &&
  //     values.zip.length === 0 &&
  //     values.emr.length === 0 &&
  //     values.bill.length === 0 &&
  //     values.AddedDate.length === 0 &&
  //     values.NotAddedDate.length === 0
  //   ) {
  //     return 1
  //   } else {
  //     return 0
  //   }
  // }

  const search = () => {
    if (isValid) {
      setCondition(2)
      // if (!checkEmpty()) {
      props.setPage(1)
      props.fetchClinics({
        salesPerson: values.salesPerson,
        speciality: values.speciality,
        status: values.status,
        clinic: values.clinic,
        city: values.city,
        zip: values.zip,
        emrName: values.emrName,
        billing: values.billing,
        email: values.email,
        state: values.state,
        trilliumMarketing: values.trilliumMarketing,
        primroseMarketing: values.primroseMarketing,
        irisMarketing: values.irisMarketing,
        cypressMarketing: values.cypressMarketing,
        phone: '',
        AddedDate: values.AddedDate,
        NotAddedDate: values.NotAddedDate,
        limit: props.ClinicsInfo.LIMIT,
        ascending: true,
        page: props.ClinicsInfo.PAGE,
        sortField: '',
      })

      props.setSearchValues({
        salesPerson: values.salesPerson,
        status: values.status,
        clinic: values.clinic,
        speciality: values.speciality,
        city: values.city,
        email: values.email,
        zip: values.zip,
        emrName: values.emrName,
        state: values.state,
        trilliumMarketing: values.trilliumMarketing,
        primroseMarketing: values.primroseMarketing,
        irisMarketing: values.irisMarketing,
        cypressMarketing: values.cypressMarketing,
        billing: values.billing,
        phone: '',
        AddedDate: values.AddedDate,
        NotAddedDate: values.NotAddedDate,
        limit: props.ClinicsInfo.LIMIT,
        ascending: true,
        page: props.ClinicsInfo.PAGE,
        sortField: '',
      })
      // }
    }
  }

  const reset = () => {
    setValues({
      ...values,
      ...{
        salesPerson: '',
        speciality: '',
        status: '',
        clinic: '',
        city: '',
        zip: '',
        emrName: '',
        email: '',
        billing: '',
        phone: '',
        state: '',
        trilliumMarketing: '',
        primroseMarketing: '',
        irisMarketing: '',
        cypressMarketing: '',
        AddedDate: '',
        NotAddedDate: '',
        limit: props.ClinicsInfo.LIMIT,
        ascending: true,
        page: props.ClinicsInfo.PAGE,
        sortField: '',
      },
    })
    props.fetchClinics({
      salesPerson: '',
      status: '',
      clinic: '',
      speciality: '',
      city: '',
      zip: '',
      emrName: '',
      billing: '',
      phone: '',
      state: '',
      email: '',
      trilliumMarketing: '',
      primroseMarketing: '',
      irisMarketing: '',
      cypressMarketing: '',
      AddedDate: '',
      NotAddedDate: '',
      limit: props.ClinicsInfo.LIMIT,
      ascending: true,
      page: props.ClinicsInfo.PAGE,
      sortField: '',
    })
  }

  useEffect(() => {
    props.fetchClinics({
      status: values.status,
      clinic: values.clinic,
      salesPerson: values.salesPerson,
      speciality: values.speciality,
      city: values.city,
      state: values.state,
      zip: values.zip,
      billing: values.billing,
      email: values.email,
      emrName: values.emrName,
      trilliumMarketing: values.trilliumMarketing,
      primroseMarketing: values.primroseMarketing,
      irisMarketing: values.irisMarketing,
      cypressMarketing: values.cypressMarketing,
      phone: '',
      AddedDate: values.AddedDate,
      NotAddedDate: values.NotAddedDate,
      limit: props.ClinicsInfo.LIMIT,
      ascending: props.ClinicsInfo.ORDER,
      page: props.ClinicsInfo.PAGE,
      sortField: props.ClinicsInfo.SORTFIELD,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.ClinicsInfo.PAGE, props.ClinicsInfo.LIMIT, props.ClinicsInfo.ORDER])

  useEffect(() => {
    if (
      props.ClinicsInfo &&
      props.ClinicsInfo.Users &&
      props.ClinicsInfo.Users.response
    ) {
      let usersArray = props.ClinicsInfo.Users.response.data
      setUsers(
        usersArray.map((user) => {
          return {
            name: user.firstName + ' ' + user.lastName,
            value: user.userId,
          }
        }),
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.ClinicsInfo.Users.response])

  return (
    <div className={classes.searchFormContainer}>
      <Paper elevation={0} className={classes.paperStyle}>
        <div className={'searchForm'}>
          <Typography variant="h3" gutterBottom className={classes.heading}>
            Contacts
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <Input
                id="clinic"
                tabIndex={11}
                type="text"
                label="Clinic"
                {...useInput('clinic', {
                  isLength: {
                    value: { max: 15 },
                    message: 'Max Length is 15',
                  },
                  // matches: {
                  //   value: /^(?=.*[a-zA-Z])[a-zA-Z0-9-']+$/,
                  //   message: 'Enter a valid name',
                  // },
                })}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <Input
                label="Email"
                type="email"
                {...useInput('email', {
                  isLength: {
                    value: { max: 50 },
                    message: 'Maximum length is 50',
                  },
                  matches: {
                    value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: 'Enter a valid email',
                  },
                })}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <Dropdown
                label="Specialty"
                options={speciality}
                {...useInput('speciality', {
                  // isRequired: {
                  //     value: true,
                  // },
                })}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <Dropdown
                label="Status"
                options={lead}
                {...useInput('status', {
                  // isRequired: {
                  //     value: true,
                  // },
                })}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <Dropdown
                label="Trillium marketing campaign"
                options={[{ name: '-- ALL --', value: '' }, ...trillCampaigns]}
                {...useInput('trilliumMarketing', {})}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <Dropdown
                label="Primrose marketing campaign"
                options={primCampaigns}
                {...useInput('primroseMarketing', {
                  // isRequired: {
                  //     value: true,
                  // },
                })}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <Dropdown
                label="Iris marketing campaign"
                options={irisCampaigns}
                {...useInput('irisMarketing', {
                  // isRequired: {
                  //     value: true,
                  // },
                })}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <Dropdown
                label="Cypress marketing campaign"
                options={cypCampaigns}
                {...useInput('cypressMarketing', {
                  // isRequired: {
                  //     value: true,
                  // },
                })}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <Dropdown
                label="State"
                options={states}
                {...useInput('state', {
                  // isRequired: {
                  //     value: true,
                  // },
                })}
              />
            </Grid>
          </Grid>
          {/* <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <Input
                id="zip"
                tabIndex={11}
                type="text"
                label="Zip"
                {...useInput('zip', {
                  isLength: {
                    value: { max: 15 },
                    message: 'Max Length is 15',
                  },
                  isNumeric: {
                    value: true,
                    message: 'Enter a valid Zip',
                  },
                })}
              />
            </Grid>
          </Grid> */}
          {/* <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <Dropdown
                label="EMR name"
                options={emrName}
                {...useInput('emrName', {
                  // isRequired: {
                  //     value: true,
                  // },
                })}
              />
            </Grid>
          </Grid> */}
          {/* <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <Dropdown
                label="Billing"
                options={billing}
                {...useInput('billing', {
                  // isRequired: {
                  //     value: true,
                  // },
                })}
              />
            </Grid>
          </Grid> */}
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <Dropdown
                label="Added within"
                options={days}
                {...useInput('AddedDate', {
                  // isRequired: {
                  //     value: true,
                  // },
                })}
              />
            </Grid>
          </Grid>
          {/* <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <Dropdown
                label="No contact within"
                options={days}
                {...useInput('NotAddedDate', {
                  // isRequired: {
                  //     value: true,
                  // },
                })}
              />
            </Grid>
          </Grid> */}
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <Button
                style={{ marginTop: '20px', width: '100%' }}
                variant="contained"
                color="primary"
                className="btn--white"
                onClick={search}
              >
                Search
              </Button>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <Button
                style={{ marginTop: '2px', width: '100%' }}
                variant="outlined"
                color="primary"
                onClick={reset}
              >
                Reset
              </Button>
            </Grid>
          </Grid>
        </div>
      </Paper>
    </div>
  )
}

const mapStateToProps = (state) => ({
  ClinicsInfo: state.clinicTable,
})

const mapDispatchToProps = (dispatch) => ({
  fetchClinics: (values) => dispatch(fetchClinics(values)),
  setSearchValues: (values) => dispatch(setSearchValues(values)),
  setPage: (values) => dispatch(setPage(values)),
  setLimit: (values) => dispatch(setLimit(values)),
})

Searchbar.propTypes = {
  ClinicsInfo: PropTypes.object,
  fetchClinics: PropTypes.func,
  setPage: PropTypes.func,
  setSearchValues: PropTypes.func,
  setLimit: PropTypes.func,
}

export default connect(mapStateToProps, mapDispatchToProps)(Searchbar)
